<template>
  <div>
    <molecules-custom-dialog
      v-if="showApproval"
      :show="showApproval"
      :title="showApproval.hkApproved ? 'Approve' : 'Reject' + '?'"
      :caption="`Anda akan ${showApproval.hkApproved ? 'approve' : 'reject'} kuotasi`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showApproval = null),
        },
        {
          title: showApproval.hkApproved ? 'Approve' : 'Reject',
          override: 'red--text',
          full: false,
          exec: () => {
            toggleApproval()
          },
        },
      ]"
    >
      <atoms-text-field color="background" label="Komentar Anda" :noLabel="true" v-model="notes"
    /></molecules-custom-dialog>
    <!-- filter -->
    <v-row>
      <v-col cols="12" md="6">
        <molecules-summary-card
          icon="text-box-plus"
          :title="`Total: ${$store.state.quotation.count} Kuotasi`"
          :cap="`Total kuotasi terhitung ${enableDate ? 'rentang' : 'hingga'}`"
          :loading="reportLoading"
          :desc="
            (enableDate &&
              `${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`) ||
            $moment().format('DD MMMM YYYY')
          "
        />
      </v-col>
      <v-col cols="12" md="6">
        <molecules-summary-card
          icon="cash-multiple"
          :title="`Nilai Total: ${$addSeparator($store.state.quotation.totalQuo)} `"
          :cap="`Nilai total kuotasi, terhitung ${enableDate ? 'rentang' : 'hingga'}`"
          :loading="reportLoading"
          :desc="
            (enableDate &&
              `${$moment(from.value).format('DD MMMM YYYY')} - ${$moment(to.value).format('DD MMMM YYYY')}`) ||
            $moment().format('DD MMMM YYYY')
          "
        />
      </v-col>
    </v-row>
    <v-form @submit.prevent="loadData">
      <v-row align="end" justify="space-between">
        <v-col cols="12" md="10">
          <atoms-text-field
            label="Cari Kuotasi"
            appendIcon="mdi-close"
            v-model="term"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
          />
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Kuotasi</atoms-button
          >
        </v-col>
      </v-row>
      <v-row align="end">
        <v-col cols="12" md="2">
          <atoms-text-field label="Status Client">
            <v-combobox
              class="rounded-lg"
              placeholder="Client"
              prepend-inner-icon="mdi-filter-variant"
              hide-details
              multiple
              v-model="filter"
              :items="filters.map((x) => x.title)"
              @change="loadData"
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </atoms-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <atoms-text-field label="Status HK">
            <v-combobox
              class="rounded-lg"
              placeholder="HK"
              prepend-inner-icon="mdi-filter-variant"
              hide-details
              multiple
              v-model="filterHK"
              :items="filtersHK.map((x) => x.title)"
              @change="loadData"
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </atoms-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <atoms-text-field label="Status">
            <v-combobox
              class="rounded-lg"
              placeholder="Status"
              prepend-inner-icon="mdi-filter-variant"
              hide-details
              v-model="filterStatus"
              :items="filterStatuses.map((x) => x.title)"
              @change="loadData"
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </atoms-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <atoms-text-field label="Pembayaran">
            <v-combobox
              class="rounded-lg"
              placeholder="Pembayaran"
              prepend-inner-icon="mdi-filter-variant"
              hide-details
              v-model="filterPayment"
              :items="filterPayments.map((x) => x.title)"
              @change="loadData"
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </atoms-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="space-between">
        <v-col cols="12" md="auto">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                loadData()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort: {{ sort }}</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <atoms-button class="teal lighten-5 teal--text text--darken-2 ml-3" @click.prevent="exportXlsx"
            ><v-icon left>mdi-google-spreadsheet</v-icon>Export XLSX
          </atoms-button>
          <atoms-button class="teal lighten-5 teal--text text--darken-2 ml-3" @click.prevent="exportXlsxQuoItems"
            ><v-icon left>mdi-google-spreadsheet</v-icon>Export XLSX Quotations Items
          </atoms-button>
        </v-col>
        <v-col cols="12" md="auto" class="d-flex justify-center justify-md-end align-center py-0 my-0">
          <v-checkbox v-model="enableDate" hide-spin-buttons /><atoms-text>Filter dengan tanggal ?</atoms-text>
        </v-col>
        <v-col cols="12" md>
          <div class="d-flex shrink justify-end align-center py-0 my-0">
            <v-icon
              @click="
                () => {
                  $showDialog({
                    title: 'Petunjuk Tabel',
                    body: `
                  <ul>
          <li>Baris Merah => on progress
            <ul>
              <li>
                Kuotasi sudah diapprove tapi belum ada invoice
              </li>
            </ul>
          </li>
          <li>Baris Kuning => partial
            <ul>
              <li>
                Invoice dari Kuotasi sudah generated
              </li>
            </ul>
          </li>
          <li>Baris Hijau => completed
            <ul>
              <li>
                Invoice dari Kuotasi sudah Lunas
              </li>
            </ul>
          </li>
          <li>Titik Biru => Kuotasi sudah memiliki Invoice</li>
        </ul>`,
                  })
                }
              "
              right
              style="cursor: pointer"
              >mdi-information</v-icon
            >
            <atoms-text class="ml-2">Petunjuk Tabel</atoms-text>
          </div>
        </v-col>
        <v-scroll-y-transition>
          <v-col cols="12" v-if="enableDate">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-dialog ref="pickerFromDialog" v-model="from.modal" :return-value.sync="from.value" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Dari Tanggal">
                      <v-text-field
                        :loading="mainLoading"
                        class="rounded-lg"
                        placeholder="Dari Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatFromDate"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="from.value"
                    scrollable
                    class="rounded-lg"
                    color="primary"
                    @click:date="$refs.pickerFromDialog.save(from.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog ref="pickerToDialog" v-model="to.modal" :return-value.sync="to.value" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Sampai Tanggal">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Sampai Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatToDate"
                        :loading="mainLoading"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        readonly
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="to.value"
                    scrollable
                    color="primary"
                    class="rounded-lg"
                    @click:date="$refs.pickerToDialog.save(to.value)"
                  />
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>
      </v-row>
    </v-form>
    <v-container fluid :class="['px-0']">
      <v-data-table
        class="rounded-lg display nowrap"
        disable-sort
        hide-default-header
        hide-default-footer
        mobile-breakpoint="0"
        :headers="headers"
        show-expand
        item-key="_id"
        :loading="mainLoading"
        :items="(!mainLoading && $store.state.quotation.data) || []"
      >
        <template v-slot:[`header`]="{ props }">
          <thead>
            <tr class="d-table-row">
              <th v-for="h in props.headers" :key="h.value" :class="['text-left', h.class]">
                <span v-if="!h.hide" class="primary--text text-uppercase">{{ h.text }}</span>
              </th>
            </tr>
          </thead></template
        >

        <template v-slot:[`item`]="{ item, index }">
          <tr class="text-left" :style="{ backgroundColor: colorStatus(item) }">
            <td>
              <v-icon
                @click="
                  () => {
                    {
                      const indexOfRow = expandedIds.indexOf(index)
                      if (indexOfRow > -1) {
                        expandedIds.splice(indexOfRow, 1)
                        return
                      }
                      expandedIds.push(index)
                    }
                  }
                "
                >mdi-chevron-{{ expandedIds.includes(index) ? 'up' : 'down' }}</v-icon
              >
            </td>
            <td class="text-center">
              <v-badge
                :value="(item.conversation && item.conversation.length) || 0"
                :color="
                  item.hadReadConversation && item.hadReadConversation.some((_id) => _id === $store.state.user._id)
                    ? 'primary'
                    : 'red darken-1'
                "
                :content="(item.conversation && item.conversation.length) || 0"
              >
                <v-icon
                  @click="
                    () => {
                      showConversation = item._id
                    }
                  "
                  >mdi-forum</v-icon
                >
              </v-badge>
            </td>
            <!-- item.areaId -->
            <td>{{ (item.client && item.client.name) || '-' }}</td>
            <td>
              {{ item.no || '-' }}
              <v-badge
                v-if="$isNumber(item.invoice && item.invoice.balanceInvoice)"
                title="Kuotasi sudah memiliki Invoice"
                class="ml-1 mb-1"
                color="primary"
                dot
              />
            </td>
            <td class="text-center">
              {{ item.type || '-' }}
            </td>
            <td class="text-center">
              <v-chip v-if="item.hkApproved == undefined" class="rounded-lg orange lighten-5 orange--text"
                >Process</v-chip
              >
              <v-chip v-else-if="item.hkApproved" class="rounded-lg green lighten-5 green--text">Approved</v-chip>
              <v-chip v-else class="rounded-lg red lighten-5 red--text">Reject</v-chip>
            </td>
            <td class="text-center">
              <v-chip v-if="item.clientApproved == undefined" class="rounded-lg orange lighten-5 orange--text"
                >Process</v-chip
              >
              <v-chip v-else-if="item.clientApproved" class="rounded-lg green lighten-5 green--text">Approved</v-chip>
              <v-chip v-else class="rounded-lg red lighten-5 red--text">Reject</v-chip>
            </td>
            <td class="text-center">
              {{ $moment(item.date).format('DD MMMM YYYY') }}
            </td>
            <td class="text-center">
              {{ $moment(item.dueDate).format('DD MMMM YYYY') }}
            </td>
            <td class="text-right">
              {{ $idCurrency(item.totalFund) || '-' }}
            </td>
            <td v-if="!$isRoles(['Area Coordinator'])" class="text-right">
              {{ $idCurrency(item.pureTotalOffer) || '-' }}
            </td>

            <td class="text-center">
              <v-menu offset-x class="rounded-xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list nav dense elevation="0" class="text-center rounded-lg">
                  <v-list-item
                    link
                    :disabled="!item.clientApproved || !item.hkApproved"
                    @click="showPayment = item._id"
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon left>mdi-cash-multiple</v-icon>
                    <v-list-item-title class="px-2">Pembayaran</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    :to="`/cetak-kuotasi?type=quotation&no=${item._id}`"
                    target="_blank"
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon>mdi-printer</v-icon>
                    <v-list-item-title class="px-2">Cetak</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    :to="`/quotations/generate/${item._id}`"
                    target="_blank"
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon>mdi-history</v-icon>
                    <v-list-item-title class="px-2">Renewal</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openEditQuo(item)" link class="rounded-lg px-4 text-center">
                    <v-icon left>mdi-file-document-edit</v-icon>
                    <v-list-item-title class="px-2">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="!$isRoles(['Sales'])"
                    link
                    class="rounded-lg px-4 text-center"
                    @click="
                      toggleApproval({
                        ...item,
                        hkApproved: true,
                      })
                    "
                  >
                    <v-icon left>mdi-sticker-check</v-icon>
                    <v-list-item-title class="px-2">Approve</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="!$isRoles(['Sales'])"
                    link
                    class="rounded-lg px-4 text-center"
                    @click="
                      showApproval = {
                        ...item,
                        hkApproved: false,
                      }
                    "
                  >
                    <v-icon left>mdi-sticker-remove</v-icon>
                    <v-list-item-title class="px-2">Reject</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          <tr v-if="$moment(item.dueDate).diff($moment(), 'days') <= 0" class="pa-2 red--text">
            <td colspan="100%">
              ↑ Kuotasi ini sudah melebihi tenggat waktu, yaitu {{ $moment(item.dueDate).format('DD MMMM YYYY') }}
            </td>
          </tr>
          <tr v-if="expandedIds.includes(index)">
            <td :colspan="headers.length" class="pa-0">
              <v-simple-table>
                <thead>
                  <tr class="d-table-row">
                    <th
                      v-for="(header, i) in [
                        { title: 'Nama Shift', class: 'text-center' },
                        { title: 'Tanggal Mulai', class: 'text-center' },
                        { title: 'Tanggal Selesai', class: 'text-center' },
                        { title: 'Area', class: 'text-center' },
                        { title: 'Lokasi', class: 'text-center' },
                        {
                          title: 'Jumlah Pekerja',
                          class: 'text-center',
                        },
                        {
                          title: 'Jumlah Hari',
                          class: 'text-center',
                        },
                        { title: 'Price', class: 'text-center' },
                        {
                          title: 'Total',
                          class: 'text-right',
                          hide: $isRoles(['Area Coordinator']),
                        },
                      ]"
                      :key="i"
                      :class="['text-left', header.class]"
                    >
                      <span
                        v-if="!header.hide"
                        :class="[
                          (item.hkApproved == undefined && 'orange--text') ||
                            (item.hkApproved && 'green--text') ||
                            'red--text',
                          'text-uppercase',
                        ]"
                        >{{ header.title }}</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(x, i) in item.items" :key="i">
                    <td class="text-left">
                      {{ x.title }}
                    </td>
                    <td class="text-center">
                      {{ x.startDate && $moment(x.startDate).format('DD MMMM yyyy') }}
                    </td>
                    <td class="text-center">
                      {{ x.endDate && $moment(x.endDate).format('DD MMMM yyyy') }}
                    </td>
                    <td class="text-left">
                      {{ x.areaTitle }}
                    </td>
                    <td class="text-left">
                      {{ x.location?.name ?? x.location }}
                    </td>
                    <td class="text-center">
                      {{ x.amount }}
                    </td>
                    <td class="text-center">
                      {{ x.quantity }}
                    </td>
                    <td class="text-center">
                      {{ $idCurrency(x.priceProposed) }}
                    </td>
                    <td v-if="!$isRoles(['Area Coordinator'])" class="text-right">
                      {{ $idCurrency(x.totalProposed) }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-divider></v-divider>
              <v-simple-table>
                <tbody>
                  <tr v-if="item.priceReduction && item.priceReduction.length > 0" class="d-table-row">
                    <td colspan="10" class="text-right">
                      <strong>Pengurangan (-)</strong>
                    </td>
                    <td colspan="2" class="text-right">
                      -{{
                        $idCurrency(item.priceReduction.map((x) => Number(x.price)).reduce((p, n) => p + n, 0)) || '-'
                      }}
                    </td>
                  </tr>
                  <!-- <tr class="d-table-row">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right">
                      <strong>Total yang harus dibayar</strong>
                    </td>
                    <td class="text-right">
                      {{ $idCurrency(item.balanceInvoice) || "-" }}
                    </td> 
                  </tr> -->
                  <tr v-if="!$isRoles(['Area Coordinator'])" class="d-table-row">
                    <td colspan="10" class="text-right">
                      <strong>Total Penawaran</strong>
                    </td>
                    <td colspan="2" class="text-right">
                      {{ $idCurrency(item.downpayment) || '-' }}
                    </td>
                  </tr>
                  <tr v-if="!$isRoles(['Area Coordinator'])" class="d-table-row">
                    <td colspan="10" class="text-right">
                      <strong>Total Modal</strong>
                    </td>
                    <td colspan="2" class="text-right">
                      {{ $idCurrency(item.totalFund) || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <atoms-text
                        ><span class="font-weight-bold">Dibuat oleh :</span>
                        {{ (item.createdBy && item.createdBy.nickname) || '-' }}
                        (<span class="primary--text">{{
                          $moment(item._createdDate).locale('en').format('DD/MM/YYYY, HH:mm:ss A')
                        }}</span
                        >)</atoms-text
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <atoms-text>
                        <span class="font-weight-bold">Terakhir Diperbarui oleh :</span>
                        {{
                          (item.updatedBy && item.updatedBy.nickname) ||
                          (item.createdBy && item.createdBy.nickname) ||
                          '-'
                        }}
                        (<span class="primary--text">{{
                          $moment(item._updatedDate).locale('en').format('DD/MM/YYYY, HH:mm:ss A')
                        }}</span
                        >)
                      </atoms-text>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <atoms-text>
                        <span class="font-weight-bold">HK Note :</span>
                        {{ (item.hkDesc && item.hkDesc) || '-' }}
                      </atoms-text>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <atoms-text>
                        <span class="font-weight-bold">Client Note :</span>
                        {{ (item.clientDesc && item.clientDesc) || '-' }}
                      </atoms-text>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-row v-if="!mainLoading" justify="center">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination class="my-4" v-model="page" :length="$store.state.quotation.pagesLength || 1"></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-slide-x-reverse-transition>
      <organism-quotation-edit
        v-if="showEdit"
        @closed="
          () => {
            getQuotations()
            showEdit = null
          }
        "
        :id="showEdit"
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-quotation-payment
        v-if="showPayment"
        @closed="
          () => {
            getQuotations()
            showPayment = null
          }
        "
        :id="showPayment"
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-quotation-conversation
        v-if="showConversation"
        :id="showConversation"
        @closed="
          () => {
            showConversation = null
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      page: 1,
      term: '',
      sort: '',
      notes: '',
      enableDate: true,
      constant: ['Desc', 'Asc'],
      expandedIds: [],
      mainLoading: false,
      reportLoading: false,
      showEdit: null,
      showApproval: null,
      showPayment: null,
      showConversation: null,
      filter: null,
      filters: [
        {
          title: this.$status.semua,
          value: {
            clientApproved: undefined,
          },
        },
        {
          title: 'Belum diproses',
          value: {
            clientApproved: {
              $exists: false,
            },
          },
        },
        {
          title: this.$status.approve,
          value: {
            clientApproved: true,
          },
        },
        {
          title: this.$status.reject,
          value: {
            clientApproved: false,
          },
        },
      ],
      filterHK: null,
      filtersHK: [
        {
          title: this.$status.semua,
          value: {
            hkApproved: undefined,
          },
        },
        {
          title: 'Belum diproses',
          value: {
            hkApproved: {
              $exists: false,
            },
          },
        },
        {
          title: this.$status.approve,
          value: {
            hkApproved: true,
          },
        },
        {
          title: this.$status.reject,
          value: {
            hkApproved: false,
          },
        },
      ],
      filterStatus: '',
      filterStatuses: [
        {
          title: 'Semua',
          value: 'Semua',
        },
        {
          title: 'On Progress',
          value: 'On Progress',
        },
        {
          title: 'Partial',
          value: 'Partial',
        },
        {
          title: 'Complete',
          value: 'Complete',
        },
      ],
      filterPayment: '',
      filterPayments: [
        {
          title: 'Semua',
          value: 'Semua',
        },
        {
          title: 'Lunas',
          value: 'Lunas',
        },
        {
          title: 'Belum Lunas',
          value: 'Belum Lunas',
        },
      ],
      headers: [
        {
          text: '',
          class: 'text-center',
          value: 'data-table-expand',
        },
        {
          text: '',
          class: 'text-center',
          width: 'auto',
        },
        {
          text: 'Klien',
        },
        {
          text: 'Nomor Kuotasi',
        },
        {
          text: 'Tipe',
          class: 'text-center',
        },
        {
          text: 'Status HK',
          class: 'text-center',
        },
        {
          text: 'Status Client',
          class: 'text-center',
        },
        {
          text: 'Tanggal',
          class: 'text-center',
        },
        {
          text: 'Tenggat',
          class: 'text-center',
        },
        {
          text: 'Total Modal',
          class: 'text-right',
        },
        {
          text: 'Nilai Kuotasi',
          class: 'text-right',
          hide: this.$isRoles(['Area Coordinator']),
        },

        {
          text: '',
          class: 'text-center',
        },
      ],
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
    }
  },
  watch: {
    // enableDate: function (newValue) {
    //   this.loadData()
    // },
    // 'from.value': function (newValue) {
    //   if (newValue) {
    //     this.loadData()
    //   }
    // },
    // 'to.value': function (newValue) {
    //   if (newValue) {
    //     this.loadData()
    //   }
    // },
    page: {
      handler() {
        this.getQuotations()
      },
      deep: true,
    },
  },
  computed: {
    formatFromDate: {
      set(value) {
        this.from.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.from.value).format('DD MMMM YYYY')
      },
    },
    formatToDate: {
      set(value) {
        this.to.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.to.value).format('DD MMMM YYYY')
      },
    },
    ...mapState(['quotation']),
  },
  beforeMount() {
    this.sort = this.constant[0]
    this.filter = [this.$status.semua]
    this.filterHK = [this.$status.semua]
    this.filterStatus = this.$status.semua
    this.filterPayment = this.$status.semua
    this.loadData()
    this.page = 1
    this.getSummary()
  },
  mounted() {
    if (this.$route.query?._id) {
      this.openEditQuo(this.$route.query?._id)
    }
  },
  methods: {
    colorStatus(quo) {
      console.log(this.$isNumber(quo?.invoice?.balanceInvoice))
      // complete
      if (quo?.invoice?.paidOff === true) {
        console.log('COMPLETE', quo)
        return '#f4fdf4'
      }
      // partial
      if (this.$isNumber(quo?.invoice?.balanceInvoice)) {
        console.log('PARTIAL', quo)
        return '#fffee9'
      }
      // on progress
      else if (quo?.hkApproved === true && quo?.clientApproved === true) {
        console.log('ON PROGRESS', quo)
        return '#FFCCCB'
      }
    },
    async toggleApproval(payload) {
      const data = this.showApproval || payload
      if (data?.hkApproved === false && this.notes.length <= 0) {
        return this.$showDialog({
          title: 'Harap isi komentar',
        })
      }
      // temporary
      const clientApproved = data?.hkApproved === true ? true : undefined
      // temporary
      this.$store.commit('SET', { loading: true })
      this.$store
        .dispatch('quotation/update', {
          ...data,
          hkDesc: this.notes || data?.hkDesc || '',
          hkApproved: data?.hkApproved || false,
          hkApproveBy: this.$store.state.user?.email,
          clientApproved,
        })
        .then(() => {
          this.notes = ''
          this.showApproval = null
          this.$showDialog({
            title: 'Berhasil',
            body: `Kuotasi telah di-${data?.hkApproved ? 'approve' : 'reject'}.`,
          })
        })
      await this.loadData()
      this.$store.commit('SET', { loading: false })
    },
    loadData() {
      this.page = 1
      this.getSummary()
      this.getQuotations()
    },
    async getSummary() {
      this.reportLoading = true
      try {
        await this.$store.dispatch('quotation/getSummary', {
          ...this.generateFilter().query,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    generateFilter() {
      const term = { $regex: this.term || '', $options: 'i' }

      let filterStatus = {}
      if (this.filterStatus === 'On Progress') {
        filterStatus = {
          $and: [
            { hkApproved: true },
            { clientApproved: true },
            { 'invoice.balanceInvoice': { $exists: false } },
            { 'invoice.paidOff': { $ne: true } },
          ],
        }
      } else if (this.filterStatus === 'Partial') {
        filterStatus = {
          $and: [{ 'invoice.balanceInvoice': { $exists: true } }, { 'invoice.paidOff': { $ne: true } }],
        }
      } else if (this.filterStatus === 'Complete') {
        filterStatus = { 'invoice.paidOff': true }
      }

      let filterPayment = {}
      if (this.filterPayment === 'Lunas') {
        filterPayment = { 'invoice.paidOff': true }
      } else if (this.filterPayment === 'Belum Lunas') {
        filterPayment = { 'invoice.paidOff': { $ne: true } }
      }

      return {
        query: {
          ...(this.filter && this.filter.length > 0
            ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
            : []),
          ...(this.filterHK && this.filterHK.length > 0
            ? Object.assign({}, ...this.filterHK?.map((x) => this.filtersHK?.find((y) => y.title === x)?.value))
            : []),
          date: this.enableDate
            ? {
                $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
                $lte: new Date(new Date(this.to.value).setHours(7, 0, 0, 0)),
              }
            : { $exists: true },
        },

        custom: {
          pipeline: [
            {
              $sort: {
                date: this.sort === this.constant[0] ? -1 : 1,
              },
            },
            {
              $lookup: {
                from: 'Clients',
                localField: 'clientId',
                foreignField: '_id',
                as: 'client',
              },
            },
            {
              $unwind: {
                path: '$client',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $match: {
                $or: [{ no: term }, { 'client.name': term }],
              },
            },
            {
              $lookup: {
                from: 'QuotationItems',
                localField: '_id',
                foreignField: 'quotationId',
                as: 'items',
              },
            },
            {
              $lookup: {
                from: 'Users',
                localField: 'createdBy',
                foreignField: '_id',
                as: 'createdBy',
              },
            },
            {
              $unwind: {
                path: '$createdBy',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $lookup: {
                from: 'Users',
                localField: 'updatedBy',
                foreignField: '_id',
                as: 'updatedBy',
              },
            },
            {
              $unwind: {
                path: '$updatedBy',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $lookup: {
                from: 'Areas',
                localField: 'areaId',
                foreignField: '_id',
                as: 'area',
              },
            },
            {
              $unwind: {
                path: '$area',
                preserveNullAndEmptyArrays: true,
              },
            },

            {
              $lookup: {
                from: 'Invoices',
                let: {
                  quoId: '$_id',
                },
                pipeline: [
                  {
                    $match: {
                      $expr: {
                        $eq: ['$_id', '$$quoId'],
                      },
                    },
                  },
                  {
                    $project: {
                      _id: 1,
                      balanceInvoice: 1,
                      paidOff: 1,
                    },
                  },
                ],
                as: 'invoice',
              },
            },
            {
              $unwind: {
                path: '$invoice',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $match: {
                ...filterStatus,
                ...filterPayment,
              },
            },
            {
              $project: {
                'createdBy.password': 0,
                'updatedBy.password': 0,
              },
            },
          ],
        },
      }
    },
    async getQuotations() {
      this.mainLoading = true
      const filter = this.generateFilter()
      try {
        await this.$store.dispatch('quotation/get', {
          ...filter,
          page: this.page,
          limit: 5,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
    async openEditQuo(item) {
      this.$store.commit('SET', { loading: true })
      try {
        const count = await this.$api
          .get(`Invoices/count`, {
            params: {
              jsonQuery: JSON.stringify({
                $and: [
                  {
                    quotationId: item?._id || item,
                  },
                  {
                    balanceInvoice: { $exists: true },
                  },
                ],
              }),
            },
          })
          .then((data) => data?.result || 0)
        // console.log(item, count)
        const isExist = count > 0
        if (!isExist) {
          this.showEdit = item?._id || item
        } else {
          this.$showDialog({
            title: 'Tidak bisa edit kuotasi',
            body: 'Tidak bisa edit kuotasi karena invoice sudah pernah disubmit, anda bisa ke halaman pembayaran atau edit invoice',
          })
        }
      } catch (err) {
        Promise.reject(err)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async exportXlsx() {
      const isConfirmed = confirm('Yakin ekspor dengan filter saat ini?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      try {
        const filter = this.generateFilter().query
        const jsonQuery = {
          jsonQuery: JSON.stringify(filter),
        }
        const query = this.$queryString.stringify(jsonQuery)
        await this.$api
          .get(`Quotations/exportXlsx/?${query}`, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              const total = parseFloat(
                progressEvent?.currentTarget?.responseHeaders?.['Content-Length'] || progressEvent.total,
              )
              const current = progressEvent?.currentTarget?.response?.length || progressEvent.loaded
              const percentCompleted = Math.floor((current / total) * 100)
              this.loadingXlsxPercentage = percentCompleted
            },
          })
          .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Quotations.xlsx`)
            document.body.appendChild(link)
            link.click()
            this.loadingXlsxPercentage = 0
          })
      } catch (err) {
        if (err?.message === 'Request failed with status code 500') {
          this.$store.commit('SET', { loading: false })
          alert('Penarikan data gagal, coba untuk perkecil range filter-nya')
        }
        alert(err?.response?.data?.message || err?.message)
      }
      this.$store.commit('SET', { loading: false })
    },
    async exportXlsxQuoItems() {
      const isConfirmed = confirm('Yakin ekspor dengan filter saat ini?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      try {
        const filter = this.generateFilter().query
        console.log(filter)
        const jsonQuery = {
          jsonQuery: JSON.stringify(filter),
        }
        const query = this.$queryString.stringify(jsonQuery)
        await this.$api
          .get(`Quotations/exportXlsxQuoItems/?${query}`, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              const total = parseFloat(
                progressEvent?.currentTarget?.responseHeaders?.['Content-Length'] || progressEvent.total,
              )
              const current = progressEvent?.currentTarget?.response?.length || progressEvent.loaded
              const percentCompleted = Math.floor((current / total) * 100)
              this.loadingXlsxPercentage = percentCompleted
            },
          })
          .then((data) => {
            console.log('result', data)
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Kuotasi Items.xlsx`)
            document.body.appendChild(link)
            link.click()
            this.loadingXlsxPercentage = 0
          })
      } catch (err) {
        // if (err?.message === 'Request failed with status code 500') {
        //   this.$store.commit('SET', { loading: false })
        // }
        alert('Penarikan data gagal, coba untuk perkecil range filter-nya atau gunakan range tanggal tertentu')
        // alert(err?.response?.data?.message || err?.message)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    // async exportXlsxQuoItems2() {
    //   this.$store.commit('SET', { loading: true })
    //   const jsonQuery = this.generateFilter().query
    //   // handle xlsx
    //   const realm = await this.$realm()
    //   const attendances = await realm.functions.getAttendances(JSON.stringify(jsonQuery))
    //   const columns = [
    //     {
    //       label: 'No KTP',
    //       value: 'No KTP',
    //     },
    //     {
    //       label: 'Nama Pekerja',
    //       value: 'Nama Pekerja',
    //     },
    //     {
    //       label: 'Perusahaan',
    //       value: 'Perusahaan',
    //     },
    //     {
    //       label: 'Kapten',
    //       value: 'Kapten',
    //     },
    //     {
    //       label: 'Area',
    //       value: 'Area',
    //     },
    //     {
    //       label: 'Gudang/Lokasi',
    //       value: 'Gudang/Lokasi',
    //     },
    //     {
    //       label: 'Hari',
    //       value: 'Hari',
    //     },
    //     {
    //       label: 'Tanggal Mulai',
    //       value: 'Tanggal Mulai',
    //     },
    //     {
    //       label: 'Jam Mulai',
    //       value: 'Jam Mulai',
    //     },
    //     {
    //       label: 'Tanggal Selesai',
    //       value: 'Tanggal Selesai',
    //     },
    //     {
    //       label: 'Jam Selesai',
    //       value: 'Jam Selesai',
    //     },
    //     {
    //       label: 'Lokasi Mulai',
    //       value: 'Lokasi Mulai',
    //     },
    //     {
    //       label: 'Lokasi Selesai',
    //       value: 'Lokasi Selesai',
    //     },
    //     {
    //       label: 'Note Mulai',
    //       value: 'Note Mulai',
    //     },
    //     {
    //       label: 'Note Selesai',
    //       value: 'Note Selesai',
    //     },
    //     {
    //       label: 'Supervisor Name',
    //       value: 'Supervisor Name',
    //     },
    //     {
    //       label: 'Supervisor Approval',
    //       value: 'Supervisor Approval',
    //     },
    //     {
    //       label: 'Jenis Rekening',
    //       value: 'Jenis Rekening',
    //     },
    //     {
    //       label: 'Nama Rekening',
    //       value: 'Nama Rekening',
    //     },
    //     {
    //       label: 'Nomor Rekening',
    //       value: 'Nomor Rekening',
    //     },
    //     {
    //       label: 'Sudah Bayar Admin',
    //       value: 'Sudah Bayar Admin',
    //     },
    //     {
    //       label: 'Referral',
    //       value: 'Referral',
    //     },
    //     {
    //       label: 'Absensi Manual',
    //       value: 'Absensi Manual',
    //     },
    //   ]
    //   const content = attendances
    //     .sort((a, b) => b.phlJamMulai - a.phlJamMulai)
    //     .map((item) => ({
    //       'No KTP': item['No KTP'],
    //       'Nama Pekerja': item['Nama Pekerja'],
    //       Perusahaan: item['Perusahaan'],
    //       Kapten: item['Kapten'],
    //       Area: item['Area'],
    //       'Gudang/Lokasi': item['Gudang/Lokasi'],
    //       Hari: item['Hari'],
    //       'Tanggal Mulai': item['Tanggal Mulai'],
    //       'Jam Mulai': item['Jam Mulai'],
    //       'Tanggal Selesai': item['Tanggal Selesai'],
    //       'Jam Selesai': item['Jam Selesai'],
    //       'Lokasi Mulai': item['Lokasi Mulai'],
    //       'Lokasi Selesai': item['Lokasi Selesai'],
    //       'Note Mulai': item['Note Mulai'],
    //       'Note Selesai': item['Note Selesai'],
    //       'Supervisor Name': item['Supervisor Name'],
    //       'Supervisor Approval': item['Supervisor Approval'],
    //       'Jenis Rekening': item['Jenis Rekening'],
    //       'Nama Rekening': item['Nama Rekening'],
    //       'Nomor Rekening': item['Nomor Rekening'],
    //       'Sudah Bayar Admin': item['Sudah Bayar Admin'],
    //       Referral: item['Referral'],
    //       'Absensi Manual': item['Absensi Manual'],
    //     }))
    //   let data = [
    //     {
    //       sheet: 'Kuotasi Items',
    //       columns,
    //       content,
    //     },
    //   ]
    //   let settings = {
    //     fileName: 'Kuotasi Items',
    //   }
    //   this.$xlsx(data, settings)
    //   this.$store.commit('SET', { loading: false })
    // },
  },
}
</script>
